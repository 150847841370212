import React from "react";

const PageTitleContacts = () => {
  return (
    <div id="page-title">
      <div className="wrapper text-center">
        <h1 className="large text-uppercase">Contact</h1>
      </div>
    </div>
  );
};

export default PageTitleContacts;
