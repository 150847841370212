import React from "react";

const ModalMenuPrimary = () => {
  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li
          className={
            "nav-item" +
            (window.location.pathname === "/" ? " current-nav-item" : "")
          }
        >
          <a title="Home" href={"/#/"}>
            Home
          </a>
        </li>

        <li
          className={
            "nav-item" +
            (window.location.pathname === "/#/about" ? " current-nav-item" : "")
          }
        >
          <a title="About us" href={"/#/about-us"}>
            About us
          </a>
        </li>

        <li
          className={
            "nav-item" +
            (window.location.pathname === "/#/services"
              ? " current-nav-item"
              : "")
          }
        >
          <a title="Services" href={"/#/services"}>
            Services
          </a>
        </li>

        <li
          className={
            "nav-item" +
            (window.location.pathname === "/#/gallery"
              ? " current-nav-item"
              : "")
          }
        >
          <a title="Gallery" href={"/#/gallery"}>
            Gallery
          </a>
        </li>

        {/* <li
          className={
            "nav-item" +
            (window.location.pathname === "/news" ? " current-nav-item" : "")
          }
        >
          <a title="News" href={"/#/news"}>
            News
          </a>
        </li> */}

        <li
          className={
            "nav-item" +
            (window.location.pathname === "/#/contact"
              ? " current-nav-item"
              : "")
          }
        >
          <a title="Contacts" href={"/#/contact"}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default ModalMenuPrimary;
