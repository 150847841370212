import React from "react";

const NewsMeta = () => {
  return (
    <div className="meta">
      <p>
        <span>
          <a href={"/news"}>By admin</a>
        </span>
        <span>Comments (1)</span>
        <span className="category">
          <a title="Cosmetic" href={"/news"}>
            Hairstyle
          </a>
        </span>
      </p>
    </div>
  );
};

export default NewsMeta;
