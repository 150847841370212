import React from "react";

const AboutOurTeam = () => {
  return (
    <div id="our-team" className="block spacer p-top-xl">
      <div className="title">
        <h2 className="hr">Our Team</h2>
      </div>

      <div className="row gutter-width-sm with-pb-md">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="team-member">
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img src="assets/img/demo/profile.jpg" alt="Alan Azarkheil" />
              </div>
            </div>

            <div className="team-member-content">
              <div className="team-member-position">
                <p>Founder</p>
              </div>

              <h4 className="team-member-t-head">Alan Azarkheil</h4>

              <div className="team-member-description">
                <p>
                  Alan Azarkheil is the founder of Golden Trim Barbershop. He is
                  a barber with over 20 years of experience.
                </p>
              </div>
            </div>

            <nav className="team-member-nav-items">
              <ul className="nav">
                <li className="nav-item">
                  <a
                    title="Facebook"
                    href="https://www.facebook.com/goldentrimbarbershop/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    title="LinkedIn"
                    href="https://www.linkedin.com/company/goldentrimbarbershop/"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    title="Instagram"
                    href="https://www.instagram.com/alan.is.barber/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOurTeam;
