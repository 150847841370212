import React from "react";

const Actions = () => {
  return (
    <div className="actions">
      <div className="actions-items">
        <a
          className="actions-item"
          href={"https://getsquire.com/booking/book/golden-trim-toronto"}
        >
          <span>Reservation</span>
        </a>
      </div>
    </div>
  );
};

export default Actions;
