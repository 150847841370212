import React from "react";

const AboutsUs = () => {
  return (
    <section id="about-us" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-sm">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div className="title">
              <h2 className="text-uppercase">
                FROM THE HEART OF TORONTO, WE WORK WITH PASSION TO ALWAYS EARN
                AND MAINTAIN YOUR TRUST FOR THE CARE OF YOUR HAIR AND BEARD.
              </h2>
            </div>
          </div>
        </div>

        <div className="row gutter-width-sm justify-content-end">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
            <div className="description">
              <p>
                It is saying that it is better to see once than to hear a
                thousand times. We are a professional team of hairdressers and
                bearders who adore their work and have ambition. It is always
                rewarding to us to have newcomers become regular clients and
                friends.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutsUs;
