import React from "react";

const ContactsUs = () => {
  return (
    <section id="contact-us" className="block">
      <div className="bg-blue text-tertiary spacer p-top-xl p-bottom-xl">
        <div className="wrapper text-center">
          <div className="title">
            <h2 className="text-uppercase">Book Now</h2>
          </div>

          <a
            href={"https://getsquire.com/booking/book/golden-trim-toronto"}
            className="btn btn-outline-tertiary text-uppercase"
          >
            GET STARTED
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactsUs;
