import React from "react";

const PageTitleUserInterface = () => {
  return (
    <div id="page-title">
      <div className="wrapper text-center">
        <h1 className="large text-uppercase">
          Golden Trim user interface (UI)
        </h1>
      </div>
    </div>
  );
};

export default PageTitleUserInterface;
