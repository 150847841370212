import React from "react";

const year = new Date().getFullYear();

const Copyright = () => {
  return (
    <div className="copyright">
      <p>
        © {year} Golden Trim Barbershop built with ❤️ and ☕ by Sal Anvarov /{" "}
        <a className="secondary-semibold" href="/contact">
          Reserve
        </a>
      </p>
    </div>
  );
};

export default Copyright;
