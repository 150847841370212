import React from "react";

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="contacts-items">
        <div className="contacts-item">
          <div className="contacts-item-description">
            <p>
              <a href="mailto:info@goldentrim.ca">info@goldentrim.ca</a>
            </p>
          </div>

          <div className="contacts-item-title">
            <h6>Email</h6>
          </div>
        </div>

        <div className="contacts-item">
          <div className="contacts-item-description">
            <p>38 Avenue Rd, Toronto, ON M5R 2G2</p>
          </div>

          <div className="contacts-item-title">
            <h6>Address</h6>
          </div>
        </div>

        <div className="contacts-item">
          <div className="contacts-item-description">
            <p>
              <a href="tel:+14169234343">416-923-4343</a>
            </p>
          </div>

          <div className="contacts-item-title">
            <h6>Phone</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
