import React from "react";

const NewsTags = () => {
  return (
    <div className="tags">
      <p>
        <a title="Beauty" href={"/news"}>
          Hairstyle
        </a>
        <a title="Cosmetic" href={"/news"}>
          Beard
        </a>
        <a title="Health" href={"/news"}>
          Cosmetic
        </a>
      </p>
    </div>
  );
};

export default NewsTags;
