import React from "react";

const PageTitleHome = () => {
  return (
    <section
      id="page-title"
      className="block with-img"
      style={{
        backgroundImage: "url(assets/img/barber/2.jpg)",
      }}
    >
      <div className="wrapper text-center d-flex">
        <div className="align-self-center w-100">
          <div className="title">
            <h1 className="large text-uppercase">GOLDEN TRIM BARBER SHOP</h1>
          </div>

          <div className="description spacer p-top-lg">
            <p>The most exceptional barbering experience in Yorkville.</p>
            <p>
              Located on{" "}
              <a
                href="https://google.com/maps/dir//golden+trim+toronto/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x882b35cb116e5791:0x1e08018b12b5cb69?sa=X&ved=1t:3061&ictx=111"
                target="_blank"
                rel="noreferrer noopener"
              >
                38 Avenue Rd, Toronto, ON M5R 2G2
              </a>
            </p>
          </div>

          <div className="spacer p-top-lg no-space">
            {/* <Link
              // href="https://getsquire.com/booking/book/golden-trim-toronto"
              to="contact-us"
              spy={true}
              smooth={true}
              duration={0}
              className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id"
            >
              BOOK with Golden Trim
            </Link> */}
            <a
              href="https://getsquire.com/booking/book/golden-trim-toronto"
              target="_blank"
              rel="noreferrer noopener"
              className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id"
            >
              BOOK with Golden Trim
            </a>
          </div>

          <nav className="languages lang-position">
            <ul className="nav">
              <li className="lang-item current-lang">
                <a title="en" href={"/"}>
                  en
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="page-title-bg-color"></div>
    </section>
  );
};

export default PageTitleHome;
