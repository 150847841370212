import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Footer from "../blocks/footer/Footer";
import Header from "../blocks/header/Header";
import Loading from "../blocks/loading/Loading";

import PageTitleContacts from "../blocks/page-title/PageTitleContacts";
import ContactForm from "../components/form/ContactForm";

import ContactsInside from "../blocks/contacts/Contacts";

const Contacts = () => {
  document.body.classList.add("page");

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Contact | Golden Trim - Barbershop</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleContacts />
        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <iframe
                  title="Golden Trim Barbershop"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11543.733392209779!2d-79.3951675!3d43.670356!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b35cb116e5791%3A0x1e08018b12b5cb69!2sGolden%20Trim%20Barbershop!5e0!3m2!1sen!2sca!4v1732239800793!5m2!1sen!2sca"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>

                <div className="spacer p-top-xl">
                  <div className="title">
                    <h2 className="text-uppercase">Let’s talk or book now</h2>
                  </div>

                  <div className="row gutter-width-sm with-pb-xl spacer p-top-lg">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                      <ContactsInside />
                    </div>

                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                      <div className="contact-form-shortcode">
                        <ContactForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Contacts;
