import React from "react";

const HeaderLogo = ({ logoColor }) => {
  return (
    <div className="header-logo">
      <a
        className="logo logo-secondary transform-scale-h"
        title="Logo"
        href={"/"}
      >
        <img src={"assets/img/logo/logo-" + logoColor + "-bg.png"} alt="Logo" />
      </a>
    </div>
  );
};

export default HeaderLogo;
