import React from "react";

const AboutImg = () => {
  return (
    <div id="about-us-img" className="block">
      <div className="about-img">
        <div className="img object-fit">
          <div className="object-fit-cover">
            <img src="assets/img/demo/17_img.png" alt="About us" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutImg;
